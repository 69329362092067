// tslint:disable
import { createRpcInvoker } from "cooke-rpc";

import { Email } from "../types";
import { Password } from "../types";

export enum ChangeEmailErrorCode {
  CodeCorrelationTokenError = "CodeCorrelationTokenError",
  IncorrectCode = "IncorrectCode",
  TooManyCodeAttempts = "TooManyCodeAttempts",
  AlreadyUsed = "AlreadyUsed"
}

export enum ChangeEmailStartErrorCode {
  IncorrectPassword = "IncorrectPassword",
  TooManyPasswordAttempts = "TooManyPasswordAttempts"
}

export enum ChangePasswordErrorCode {
  IncorrectPassword = "IncorrectPassword",
  InsufficientPassword = "InsufficientPassword",
  TooManyPasswordAttempts = "TooManyPasswordAttempts"
}

export enum OrderStatus {
  Created = "Created",
  Paid = "Paid",
  Cancelled = "Cancelled",
  Declined = "Declined",
  Error = "Error"
}

export enum ProcessOrderResult {
  Processing = "Processing",
  Paid = "Paid",
  Error = "Error",
  Cancelled = "Cancelled",
  Declined = "Declined"
}

export enum RegisterErrorCode {
  CodeCorrelationTokenError = "CodeCorrelationTokenError",
  TooManyCodeAttempts = "TooManyCodeAttempts",
  IncorrectCode = "IncorrectCode",
  AlreadyUsed = "AlreadyUsed"
}

export enum RemoveAccountResult {
  Removed = "Removed",
  IncorrectPassword = "IncorrectPassword",
  TooManyPasswordAttempts = "TooManyPasswordAttempts"
}

export enum ResetPasswordErrorCode {
  BindingTokenError = "BindingTokenError",
  IncorrectCode = "IncorrectCode",
  AlreadyUsed = "AlreadyUsed",
  TooManyAttempts = "TooManyAttempts",
  UserNotFound = "UserNotFound"
}

export enum VerifyPasswordResetCodeResult {
  BindingTokenError = "BindingTokenError",
  IncorrectCode = "IncorrectCode",
  Ok = "Ok",
  AlreadyUsed = "AlreadyUsed",
  TooManyAttempts = "TooManyAttempts"
}

export interface AccountData {
  $type?: "AccountData";
  accessUntil: string;
  email?: null | Email;
  id: number;
  payments: Array<Order>;
}

export interface AdminWord {
  $type?: "AdminWord";
  examples: Array<AdminWordExample>;
  explanation: string;
  hasImage: boolean;
  id: string;
  name: string;
}

export interface AdminWordExample {
  $type?: "AdminWordExample";
  content: string;
  id: number;
  name: string;
}

export interface AdminWordListItem {
  $type?: "AdminWordListItem";
  hasImage: boolean;
  id: string;
  name: string;
}

export interface ChangeEmailErrorResult extends ChangeEmailResult$Base {
  $type: "ChangeEmailErrorResult";
  code: ChangeEmailErrorCode;
}

export interface ChangeEmailInput {
  $type?: "ChangeEmailInput";
  code: string;
  correlationToken: string;
}

export interface ChangeEmailResult$Base {

}

export type ChangeEmailResult = ChangeEmailErrorResult | ChangeEmailSuccessResult;

export interface ChangeEmailStartError extends ChangeEmailStartResult$Base {
  $type: "ChangeEmailStartError";
  code: ChangeEmailStartErrorCode;
}

export interface ChangeEmailStartInput {
  $type?: "ChangeEmailStartInput";
  newEmail: Email;
  password: string;
}

export interface ChangeEmailStartResult$Base {

}

export type ChangeEmailStartResult = ChangeEmailStartError | ChangeEmailStartSuccess;

export interface ChangeEmailStartSuccess extends ChangeEmailStartResult$Base {
  $type: "ChangeEmailStartSuccess";
  correlationToken: string;
}

export interface ChangeEmailSuccessResult extends ChangeEmailResult$Base {
  $type: "ChangeEmailSuccessResult";

}

export interface ChangePasswordError extends ChangePasswordResult$Base {
  $type: "ChangePasswordError";
  code: ChangePasswordErrorCode;
}

export interface ChangePasswordResult$Base {

}

export type ChangePasswordResult = ChangePasswordError | ChangePasswordSuccess;

export interface ChangePasswordSuccess extends ChangePasswordResult$Base {
  $type: "ChangePasswordSuccess";

}

export interface CreateWordInput {
  $type?: "CreateWordInput";
  id: string;
  word: string;
}

export interface FinishOrderInput {
  $type?: "FinishOrderInput";
  error?: null | string;
  merchantReference: string;
  transactionId?: null | string;
}

export interface InitiateOrderResponse {
  $type?: "InitiateOrderResponse";
  dinteroSessionId: string;
}

export interface ListOrder {
  $type?: "ListOrder";
  amount: number;
  createdAt: string;
  dinteroAccount: string;
  dinteroTransactionId?: null | string;
  id: string;
  status: OrderStatus;
  updatedAt?: null | null | string;
  userEmail?: null | string;
  userId?: null | null | number;
}

export interface ListUser {
  $type?: "ListUser";
  accessUntil: string;
  createdAt: string;
  email?: null | string;
  id: number;
  lastLogin: string;
}

export interface LoginFailed extends LoginResult$Base {
  $type: "LoginFailed";

}

export interface LoginResult$Base {

}

export type LoginResult = LoginFailed | LoginSuccess;

export interface LoginSuccess extends LoginResult$Base {
  $type: "LoginSuccess";
  sessionData: SessionData;
}

export interface NoSessionResult extends SessionResult$Base {
  $type: "NoSessionResult";

}

export interface Order {
  $type?: "Order";
  createdAt: string;
  id: string;
  status: OrderStatus;
  total: number;
  userReference?: null | string;
  vat: number;
  vatPercent: number;
}

export interface RegisterError extends RegisterResponse$Base {
  $type: "RegisterError";
  code: RegisterErrorCode;
}

export interface RegisterInput {
  $type?: "RegisterInput";
  code: string;
  codeCorrelationToken: string;
}

export interface RegisterResponse$Base {

}

export type RegisterResponse = RegisterError | RegisterSuccess;

export interface RegisterStartInput {
  $type?: "RegisterStartInput";
  email: Email;
  password: Password;
}

export interface RegisterStartResult {
  $type?: "RegisterStartResult";
  correlationToken: string;
}

export interface RegisterSuccess extends RegisterResponse$Base {
  $type: "RegisterSuccess";
  sessionData: SessionData;
}

export interface RequestPasswordResetResult {
  $type?: "RequestPasswordResetResult";
  correlationToken: string;
}

export interface ResetPasswordError extends ResetPasswordResult$Base {
  $type: "ResetPasswordError";
  code: ResetPasswordErrorCode;
}

export interface ResetPasswordResult$Base {

}

export type ResetPasswordResult = ResetPasswordError | ResetPasswordSuccess;

export interface ResetPasswordSuccess extends ResetPasswordResult$Base {
  $type: "ResetPasswordSuccess";
  sessionData: SessionData;
}

export interface SessionData {
  $type?: "SessionData";
  accessUntil: string;
  role?: null | string;
  userId: number;
}

export interface SessionResult$Base {

}

export type SessionResult = NoSessionResult | UserSession;

export interface UpdateUserInput {
  $type?: "UpdateUserInput";
  accessUntil?: string | undefined;
  email?: Email | undefined;
  isAdmin?: boolean | undefined;
}

export interface User {
  $type?: "User";
  accessUntil: string;
  createdAt: string;
  email?: null | string;
  failedPasswordAttempts: number;
  id: number;
  isAdmin: boolean;
  lastLogin: string;
  orders: Array<UserOrder>;
  userPasswordLockoutAt?: null | null | string;
}

export interface UserOrder {
  $type?: "UserOrder";
  amount: number;
  createdAt: string;
  dinteroTransactionId?: null | string;
  id: string;
  status: OrderStatus;
  updatedAt?: null | null | string;
}

export interface UserSession extends SessionResult$Base {
  $type: "UserSession";
  data: SessionData;
}

export interface Word {
  $type?: "Word";
  examples: Array<WordExample>;
  explanation: string;
  hasImage: boolean;
  id: string;
  name: string;
}

export interface WordExample {
  $type?: "WordExample";
  name: string;
  text: string;
}

export interface WordExampleInput {
  $type?: "WordExampleInput";
  content: string;
  name: string;
}

export interface WordListItem {
  $type?: "WordListItem";
  id: string;
  name: string;
}

export const accountController = {
  fetch: createRpcInvoker<'AccountController', 'Fetch', [], AccountData>('AccountController', 'Fetch'),
  changeEmailStart: createRpcInvoker<'AccountController', 'ChangeEmailStart', [input: ChangeEmailStartInput], ChangeEmailStartResult>('AccountController', 'ChangeEmailStart'),
  changeEmail: createRpcInvoker<'AccountController', 'ChangeEmail', [input: ChangeEmailInput], ChangeEmailResult>('AccountController', 'ChangeEmail'),
  changePassword: createRpcInvoker<'AccountController', 'ChangePassword', [currentPassword: string, newPassword: Password], ChangePasswordResult>('AccountController', 'ChangePassword'),
  removeAccount: createRpcInvoker<'AccountController', 'RemoveAccount', [password: string, reason: string], RemoveAccountResult>('AccountController', 'RemoveAccount'),
}

export const adminOrderController = {
  getOrders: createRpcInvoker<'AdminOrderController', 'GetOrders', [], Array<ListOrder>>('AdminOrderController', 'GetOrders'),
}

export const adminUserController = {
  getUsers: createRpcInvoker<'AdminUserController', 'GetUsers', [], Array<ListUser>>('AdminUserController', 'GetUsers'),
  getUser: createRpcInvoker<'AdminUserController', 'GetUser', [id: number], null | User>('AdminUserController', 'GetUser'),
  updateUser: createRpcInvoker<'AdminUserController', 'UpdateUser', [id: number, input: UpdateUserInput], void>('AdminUserController', 'UpdateUser'),
  deleteUser: createRpcInvoker<'AdminUserController', 'DeleteUser', [id: number], void>('AdminUserController', 'DeleteUser'),
}

export const adminWordController = {
  getWordList: createRpcInvoker<'AdminWordController', 'GetWordList', [], Array<AdminWordListItem>>('AdminWordController', 'GetWordList'),
  getWord: createRpcInvoker<'AdminWordController', 'GetWord', [id: string], null | AdminWord>('AdminWordController', 'GetWord'),
  createWord: createRpcInvoker<'AdminWordController', 'CreateWord', [input: CreateWordInput], void>('AdminWordController', 'CreateWord'),
  updateWord: createRpcInvoker<'AdminWordController', 'UpdateWord', [wordId: string, explanation: string], void>('AdminWordController', 'UpdateWord'),
  createWordExample: createRpcInvoker<'AdminWordController', 'CreateWordExample', [wordId: string], number>('AdminWordController', 'CreateWordExample'),
  updateWordExample: createRpcInvoker<'AdminWordController', 'UpdateWordExample', [exampleId: number, input: WordExampleInput], void>('AdminWordController', 'UpdateWordExample'),
  removeWord: createRpcInvoker<'AdminWordController', 'RemoveWord', [wordId: string], void>('AdminWordController', 'RemoveWord'),
  removeWordExample: createRpcInvoker<'AdminWordController', 'RemoveWordExample', [exampleId: number], void>('AdminWordController', 'RemoveWordExample'),
}

export const orderController = {
  initiateOrder: createRpcInvoker<'OrderController', 'InitiateOrder', [], InitiateOrderResponse>('OrderController', 'InitiateOrder'),
  processOrder: createRpcInvoker<'OrderController', 'ProcessOrder', [input: FinishOrderInput], ProcessOrderResult>('OrderController', 'ProcessOrder'),
}

export const sessionController = {
  fetchSession: createRpcInvoker<'SessionController', 'FetchSession', [], SessionResult>('SessionController', 'FetchSession'),
  logout: createRpcInvoker<'SessionController', 'Logout', [], void>('SessionController', 'Logout'),
  requestPasswordReset: createRpcInvoker<'SessionController', 'RequestPasswordReset', [email: Email], RequestPasswordResetResult>('SessionController', 'RequestPasswordReset'),
  verifyPasswordResetCode: createRpcInvoker<'SessionController', 'VerifyPasswordResetCode', [bindingToken: string, code: string], VerifyPasswordResetCodeResult>('SessionController', 'VerifyPasswordResetCode'),
  resetPassword: createRpcInvoker<'SessionController', 'ResetPassword', [correlationToken: string, code: string, newPassword: Password], ResetPasswordResult>('SessionController', 'ResetPassword'),
  login: createRpcInvoker<'SessionController', 'Login', [emailOrUsername: Email, password: string], LoginResult>('SessionController', 'Login'),
  registerStart: createRpcInvoker<'SessionController', 'RegisterStart', [input: RegisterStartInput], RegisterStartResult>('SessionController', 'RegisterStart'),
  register: createRpcInvoker<'SessionController', 'Register', [input: RegisterInput], RegisterResponse>('SessionController', 'Register'),
}

export const wordController = {
  getWordList: createRpcInvoker<'WordController', 'GetWordList', [], Array<WordListItem>>('WordController', 'GetWordList'),
  getWord: createRpcInvoker<'WordController', 'GetWord', [id: string], null | Word>('WordController', 'GetWord'),
}

