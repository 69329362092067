export const routes = {
  login: {
    path: "/logga-in",
  },
  word: {
    path: "/ord/:wordId",
  },
  words: {
    path: "/ord",
  },
  users: "/users",
  user: "/users/:userId",
  orders: "/orders",
} as const;
