const defaultConfig = {
  serverUrl: "http://localhost:5000",
  imagesUrl: "https://localhost.cellar-fr-north-hds-c1.services.clever-cloud.com",
};

const configMap: { [key: string]: Partial<typeof defaultConfig> } = {
  "http://192.168.1.52:3000": {
    serverUrl: "http://192.168.1.52:5000",
  },
  "http://192.168.1.43:3000": {
    serverUrl: "http://192.168.1.43:5000",
  },
  "https://admin-test.simpliord.se": {
    serverUrl: "https://api-test.simpliord.se",
    imagesUrl: "https://cellar-fr-north-hds-c1.services.clever-cloud.com/images-test.simpliord.se",
  },
  "https://admin.simpliord.se": {
    serverUrl: "https://api.simpliord.se",
    imagesUrl: "https://images.simpliord.se",
  },
};

export const config = {
  ...defaultConfig,
  ...configMap[window.location.origin],
};

export const serverUrl = config.serverUrl;
